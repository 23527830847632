
.order-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .answer{
        display: flex;
        align-items: center;
        img{
            width: 80px;
            height: 80px;
        }
        .text{
            padding-left: 12px;
        }
    }
    .order-item{
        display: flex;
        align-items: center;
        ::v-deep .el-form-item__content{
            display: flex;
            margin-left: 24px!important;
        }
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }
    .dialog-footer{
        margin-top: 20px;
        text-align: center;
    }
}

